import React from 'react'
import LogoVisiconnect from "./../logoVisiconnect.svg";
import LogoByVisiperf from "./../logoByVisiperf.svg";
import LogoVisiperf from "./../logo-visiperf.png";

const Nav = ({redirectURL}) => {
  redirectURL = redirectURL ? new URL(redirectURL) : new URL("https://visiperf.io")
  return(
      <div className="bs-component">
        <nav className="navbar navbar-expand-lg navbar-dark bg-primary">
          <div className="container">
            <div>
            <a className="navbar-brand">
              <img src={LogoVisiconnect} alt="logo Visiconnect" />
            </a>
              <img src={LogoByVisiperf} alt="Logo By Visiperf" />
              <img src={LogoVisiperf} alt="Visiperf" style={{height: 50}}/>
            </div>
            
            <div>
              <a className="navbar-brand" href={ redirectURL.href }>Retour sur <span style={{textDecoration: "underline"}}>{redirectURL.hostname}</span></a>
            </div>
          </div>
        </nav>
      </div>
  )}
  
  export default Nav;
import	fetch	from 'isomorphic-unfetch';

export const	getFacebookPicture = (id, access) =>
{
	return (`https://graph.facebook.com/${id}/picture?type=normal&access_token=${access}`);
};

export const	fetchGmbAccounts = (accessToken, callback) =>
{
	return (
		fetch(`https://mybusiness.googleapis.com/v4/accounts/`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
		.then(async (response) =>
		{
			const	json = await response.json();
			if (callback && json)
				callback(json);
			return (json);
		})
		.catch(err => console.warn(err))
	);
};

export const	fetchGmbLocations = (accessToken, accountName, callback) =>
{
	return (
		fetch(`https://mybusiness.googleapis.com/v4/${accountName}/locations`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
		.then(async (response) =>
		{
			const	json = await response.json();
			if (callback && json)
				callback(json);
			return (json);
		})
		.catch(err => console.warn(err))
	);
};

export const	createGMBLocation = (accessToken, googleId, callback) =>
{
	const	data = {
		language_code: `fr`,
		locationName: `SHOKI ALTERNATIF 01`,
		primaryCategory: {
			categoryId: `gcid:software_company`,
			displayName: `Software company`,
		},
		address: {
			locality: `Saint-Ouen`,
			postal_code: `93200`,
			region_code: `FR`,
			address_lines: [`228 boulevard Anatole France`]
		}
	}
	return (
		fetch(`https://mybusiness.googleapis.com/v4/accounts/${googleId}/locations?validateOnly=true&requestId=XXXXXX`, {
			method: 'POST',
			body: JSON.stringify(data),
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
		.then(async (response) =>
		{
			const	json = await response.json();
			if (callback && json)
				callback(json);
			return (json);
		})
		.catch(err => console.warn(err))
	);
};

export const	fetchGMBTest = (accessToken, locationName, callback) =>
{
	return (
		fetch(`https://mybusiness.googleapis.com/v4/${locationName}/media`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		})
		.then(async (response) =>
		{
			const	json = await response.json();
			if (callback && json)
				callback(json);
			return (json);
		})
		.catch(err => console.warn(err))
	);
};

export const	fetchGmbStats = (accessToken, googleId, locationName, startTime, endTime, callback) =>
{
	const	data = {
		locationNames: [locationName],
		basicRequest : {
			metricRequests:	[{metric: 'ALL'}],
			timeRange:	{startTime, endTime}
		}
	};
	return (
		fetch(`https://mybusiness.googleapis.com/v4/accounts/${googleId}/locations:reportInsights`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${accessToken}`
			},
			body:	JSON.stringify(data)
		})
		.then(async (response) =>
		{
			const	json = await response.json();
			if (callback && json)
				callback(json);
			return (json);
		})
		.catch(err => console.warn(err))
	);
};
import React from 'react';

export const Title = ({redirectUrl, media}) => {
  redirectUrl = redirectUrl ? new URL(redirectUrl) : new URL("https://visiperf.io")
  return (
  <div>
    <h3><strong>{
      redirectUrl.hostname.toUpperCase()
    }</strong> demande l’autorisation d’accéder à votre compte <strong>{media}</strong></h3>
  </div>)
  }
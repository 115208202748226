import React from "react";
import { Component } from "react";
import { withRouter } from "react-router-dom";

import { Dropdown } from "../components/dropdown";
import Layout from "../components/Layout";
import PopIn from "../components/PopIn";
import Steps from "../components/Steps";
import { Title } from "../components/Title";
import * as API from "../utils/API";
import * as ProxyCalls from "../utils/ProxyCalls";

import useQuery from "./../utils/useQuery";
import ConnectionGoogle from "../components/ConnectionGoogle";

class gtmConnection extends Component {
  constructor(props) {
    super(props);

    let query = useQuery(props.location.search);

    this.state = {
      media: "GOOGLE",
      user: {
        accessToken: "",
        accessExp: "",
        refreshToken: query.get("refreshToken") || "",
        refreshExp: ""
      },
      redirectUrl: query.get("redirectUrl") || "",
      businessID: query.get("businessID") || "",
      wait: false,
      error: "",
      done: false,
      arrivalStep: 1,
      google: {
        locationSelected: null,
        accountSelected: null,
        accounts: [],
        locations: [],
        userToken: "",
        accessSelected: {},
        profileObj: {},
        accessToken: "",
        accesses: []
      }
    };

    if (this.state.user.refreshToken) {
      try {
        ProxyCalls.getUserToken(this.state.user.refreshToken).then(e => {
          let error = "";
          let user = "";
          let accesses = [];
          if (e && e.message) {
            error = e.message;
          } else if (e.accessToken) {
            user = e;
            ProxyCalls.getGoogleAccesses(user.accessToken).then(e => {
              if (e && e.message) {
                error = e.message;
              } else {
                accesses = e.data;
                this.setState({ google: {...this.state.google, accesses: accesses}})
              }
            })
          }
          error = (!user && !error ? "undefined error" : error)
          this.setState({ wait: false, user: user, error: error});
        });
      } catch (error) {
        this.setState({ error: error });
      }
    } else {
      this.state.error = "Query string refreshToken not defined or incorrect.";
    }

    if (!this.state.businessID) {
      this.state.error = "Query string businessID not defined or incorrect.";
    }

    if (!this.state.redirectUrl) {
      this.state.error = "Query string redirectUrl not defined or incorrect.";
    }
  }

  componentDidUpdate() {
    if (this.state.done) {
      setTimeout(() => {
        document.location.href = this.state.redirectUrl;
      }, 2000);
    }
    return true;
  }

  setInfo = e => {
    this.setState({
      ...this.state,
      wait: true,
    });
    try {
      ProxyCalls.postGoogleCode(e.code, this.state.user.accessToken).then(e => {
          let error = "";
          let google = {};
          if (e && e.message) {
            error = e.message;
          } else {
            google = e.data;
          }
          this.setState({
            google: { ...this.state.google, accessSelected: google },
            error: error
          });

          if (google.accessToken) {
            API.fetchGmbAccounts(google.accessToken).then(allAccounts => {
              if (allAccounts && allAccounts.accounts) {
                this.setState({
                  ...this.state,
                  arrivalStep: this.state.arrivalStep + 1,
                  wait: false,
                  google: {
                    ...this.state.google,
                    accounts: allAccounts.accounts,
                    accountSelected: allAccounts.accounts[0].name
                  }
                });
              }
              this.setLocations(google.accessToken, allAccounts.accounts[0].name);
            });
          }
        }
      );
    } catch (error) {
      this.setState({ error: error });
      return;
    }
  };

  setLocations(accessToken, accountSelected) {
    this.setState({
      ...this.state,
      wait: true,
      google: { ...this.state.google, locations: [], locationSelected: null, accountSelected: accountSelected}
    });

    API.fetchGmbLocations(accessToken || this.state.google.accessToken, accountSelected).then(res => {
      if (res && res.locations && res.locations.length > 0) {
        this.setState({
          ...this.state,
          wait: false,
          google: {
            ...this.state.google,
            locations: res.locations,
            locationSelected: null
          }
        });
      } else {
        this.setState({
          ...this.state,
          wait: false,
          google: { ...this.state.google, locations: [], locationSelected: null }
        });
      }
    });
  }

  renderAccounts() {
    if (this.state.google.accounts && this.state.google.accounts.length > 0) {
      const selects = this.state.google.accounts.map((eachItem, index) => (
        <option key={index} value={eachItem.name}>
          {eachItem.accountName}
        </option>
      ));
      return (
        <div className="form-group">
          <label htmlFor="exampleSelect1">Vos comptes</label>
          <select
            className="form-control"
            id="exampleSelect1"
            onChange={e => {
              this.setLocations(this.state.google.accessSelected.accessToken, e.target.value)
              }
            }
          >
            {selects}
          </select>
        </div>
      );
    }
  }

  setSelectedLocation = locationSelected => {
    this.setState({
      google: { ...this.state.google, locationSelected: locationSelected.value.name }
    });
  };

  setSelectedAccess = accessId => {
    this.setState({
      ...this.state,
      wait: true,
    });
    let access = this.state.google.accesses.find(e => e.id === accessId)

    this.setState({
      google: { ...this.state.google, accessSelected: access }
    });
    if (access.accessToken) {
      API.fetchGmbAccounts(access.accessToken).then(allAccounts => {
        if (allAccounts && allAccounts.accounts) {
          this.setState({
            ...this.state,
            arrivalStep: this.state.arrivalStep + 1,
            wait: false,
            google: {
              ...this.state.google,
              accounts: allAccounts.accounts,
              accountSelected: allAccounts.accounts[0].name
            }
          });
          this.setLocations(access.accessToken, allAccounts.accounts[0].name);
        }
      });
    }
  };

  validAction = function() {
    this.setState({ wait: true });

    ProxyCalls.postGoogleGTM(this.state.google, this.state.businessID, this.state.user.accessToken).then(e => {
      if (e && e.data)
        this.setState({ wait: false, done: true });
      else if(e && e.message) 
        this.setState({ wait: false, error: e.message });
    });
  };


  render() {
    return (
      <Layout redirectURL={this.state.redirectUrl}>
        <Title redirectUrl={this.state.redirectUrl} media={this.state.media} />
        <Steps
          number={1}
          active={this.state.arrivalStep === 1}
          done={this.state.arrivalStep > 1}
        >
          <div className="col">
            <h4 className="card-title">Connexion à votre compte Google</h4>
            <p className="card-text">
              La connexion à votre compte Google nous permet d’accéder aux
              Google My Business associées à votre compte.
            </p>
          </div>
          <ConnectionGoogle 
            active={this.state.arrivalStep === 1} 
            accesses={this.state.google.accesses}
            accessSelected={this.state.google.accessSelected}
            setSelectedAccess={this.setSelectedAccess}
            setInfo={this.setInfo}
          />
        </Steps>
        <Steps
          number={2}
          active={this.state.arrivalStep === 2}
          done={this.state.arrivalStep > 2}
        >
          <div>
            <h4 className="card-title">
              Séléctionnez la(les) localisation(s) localisation à associer à
              votre compte Visiretail
            </h4>
            {this.renderAccounts()}
            <p className="card-text">
              Autorisez Visiretail à accéder à vos localisation{" "}
              <strong>Google My Business</strong> pour :{" "}
            </p>
            <ul>
              <li>
                Mise à jour automatique de vos données sur votre page quand vous
                effectuez une demande de modifications.
              </li>
              <li>
                Posts automatiques de vos demandes de publications sur votre
                page <strong>Google My Business</strong>.
              </li>
              <li>
                Récupération des avis et des commentaires reçus sur votre page
                afin de vous permettre d'y répondre depuis Visiretail.
              </li>
              <li>Récupértion des statistiques de performance.</li>
            </ul>
            <form>
              <div className="form-group">
                <label>
                  Vos pages <strong>(Google My Business)</strong>:
                </label>
                <Dropdown
                  media={this.state.media}
                  data={this.state.google.locations}
                  active={this.state.arrivalStep === 2}
                  selectedOption={this.setSelectedLocation}
                  selected={this.state.google.locationSelected}
                  isSelected={() => this.isSelected}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                disabled={this.state.arrivalStep !== 2 || this.state.google.locationSelected === null}
                onClick={() => this.validAction()} >
                VALIDER ET SYNCHRONISER
              </button>
            </form>
          </div>
        </Steps>{" "}
        {this.state.done || this.state.error || this.state.wait ? (
          <PopIn
            waiting={this.state.wait}
            error={this.state.error}
            media={this.state.media}
            redirectUrl={this.state.redirectUrl}
          />
        ) : null}
      </Layout>
    );
  }
}

export default withRouter(gtmConnection);

import React from "react";
import { Component } from "react";
import FacebookLogin from "react-facebook-login";
import { withRouter } from "react-router-dom";

import { Dropdown } from "../components/dropdown";
import Layout from "../components/Layout";
import PopIn from "../components/PopIn";
import Steps from "../components/Steps";
import { Title } from "../components/Title";
import * as ProxyCalls from "../utils/ProxyCalls";

import useQuery from "./../utils/useQuery";

const APP_FACEBOOK_ID = process.env.REACT_APP_FACEBOOK_ID;

class FacebookConnection extends Component {
  constructor(props) {
    super(props);

    let query = useQuery(props.location.search);

    this.state = {
      media: "FACEBOOK",
      user: {
        accessToken: "",
        accessExp: "",
        refreshToken: query.get("refreshToken") || "",
        refreshExp: ""
      },
      redirectUrl: query.get("redirectUrl") || "",
      businessID: query.get("businessID") || "",
      wait: false,
      error: "",
      done: false,
      arrivalStep: 1,
      pagesSelected: [],
      facebook: { accounts: [], accessToken: "", id: "", nextUrl: "" }
    };

    if (this.state.user.refreshToken) {
      try {
        ProxyCalls.getUserToken(this.state.user.refreshToken).then(e => {
          let error = "";
          let user = "";
          if (e && e.message) {
            error = e.message;
          } else {
            user = e;
          }
          error = (!user && !error ? "undefined error" : error)
          this.setState({ wait: false, user: user, error: error });
        });
      } catch (error) {
        this.setState({ error: error });
      }
    } else {
      this.state.error = "Query sting refreshToken not defined or incorrect.";
    }

    if (!this.state.businessID) {
      this.state.error = "Query sting businessID not defined or incorrect.";
    }

    if (!this.state.redirectUrl) {
      this.state.error = "Query sting redirectUrl not defined or incorrect.";
    }
  }

  componentDidUpdate() {
    if (this.state.done) {
      setTimeout(() => {
        document.location.href = this.state.redirectUrl;
      }, 2000);
    }
    return true;
  }

  isSelected = function(id) {
    return this.state.pagesSelected.includes(id);
  };

  validAction = function() {
    this.setState({ wait: true });
    let data = this.state.pagesSelected.value;

    ProxyCalls.postFacebookPages(data, this.state.businessID, this.state.user.accessToken).then(e => {
      if (e && e.data) {
        this.setState({ wait: false, done: true});
      } else if (e && e.message) {
        this.setState({ error: e.message});
      }
    });
  };

  setSelectedPages = SelectedPages => {
    this.setState({ pagesSelected: SelectedPages });
  };

  setFacebookInformation = res => {
    this.setState({
      ...this.state,
      facebook: {
        accounts: res.accounts.data,
        accessToken: res.accessToken,
        id: res.id,
        nextUrl:
          res.accounts.paging.next || ""
      },
      arrivalStep: this.state.arrivalStep + 1
    });
  };

  render() {
    return (
      <Layout redirectURL={this.state.redirectUrl}>
        <Title redirectUrl={this.state.redirectUrl} media={this.state.media} />
        <Steps
          number={1}
          active={this.state.arrivalStep === 1}
          done={this.state.arrivalStep > 1}
        >
          <div>
            <h4 className="card-title">Connexion à votre compte Facebook</h4>
            <p className="card-text">
              La connexion à votre compte Facebook nous permet d’accéder aux
              pages Facebook associées à votre compte.
            </p>
          </div>
          <div>
            {this.state.arrivalStep === 1 ? (
              <FacebookLogin
                appId={APP_FACEBOOK_ID}
                fields={"accounts"}
                version={3.3}
                scope={
                  "read_insights,ads_read,business_management,groups_access_member_info,public_profile,manage_pages,pages_show_list,publish_pages"
                }
                textButton={"Connection avec Facebook"}
                cssClass={"facebookButton btn btn-primary btn-lg btn-block"}
                icon={"fa-facebook"}
                callback={e => {
                  this.setFacebookInformation(e);
                }}
                onFailure={e => console.log(e)}
              />
            ) : (
              <a href="#" style={{ pointerEvents: "none" }}>
                Connecter
              </a>
            )}
          </div>
        </Steps>
        <Steps
          number={2}
          active={this.state.arrivalStep === 2}
          done={this.state.arrivalStep > 2}
        >
          <div>
            <h4 className="card-title">
              Séléctionnez la / les page(s) à synchroniser à votre compte
              Visiretail
            </h4>
            <p className="card-text">
              Autorisez Visiretail à accéder à votre page Facebook pour :{" "}
            </p>
            <ul>
              <li>
                Mise à jour automatique de vos données sur votre page quand vous
                effectuez une demande de modifications.
              </li>
              <li>
                Posts automatiques de vos demandes de publications sur votre
                page.
              </li>
              <li>
                Récupération des avis et des commentaires reçus sur votre page
                afin de vous permettre d'y répondre depuis Visiretail.
              </li>
              <li>Récupértion des statistiques de performance.</li>
            </ul>
            <form>
              <div className="form-group">
                <label>
                  Vos pages <strong>(Facebook)</strong>:
                </label>
                <Dropdown
                  media={this.state.media}
                  data={this.state.facebook.accounts}
                  active={this.state.arrivalStep === 2}
                  selectedOption={this.setSelectedPages}
                  isSelected={() => this.isSelected}
                  selected={this.state.pagesSelected.value}
                  nextUrl={this.state.facebook.nextUrl}
                />
              </div>
              <button
                type="button"
                className="btn btn-primary btn-lg btn-block"
                disabled={this.state.arrivalStep !== 2 || this.state.pagesSelected.length < 1}
                onClick={() => this.validAction()}
              >
                VALIDER ET SYNCHRONISER
              </button>
            </form>
          </div>
        </Steps>
        {this.state.done || this.state.error || this.state.wait ? (
          <PopIn
            waiting={this.state.wait}
            error={this.state.error}
            media={this.state.media}
            redirectUrl={this.state.redirectUrl}
          />
        ) : null}
      </Layout>
    );
  }
}

export default withRouter(FacebookConnection);

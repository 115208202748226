import React from 'react';
import {useState} from 'react'
import GoogleLogin from 'react-google-login';
import Select from "react-select";


const APP_GOOGLE_ID = process.env.REACT_APP_GOOGLE_ID;

export const Dropdown = ({accesses, setSelectedAccess}) => {
    const [selectedOption, setSelectedOption] = useState([])

    if (!accesses || accesses.length <= 0) return null

    let options = []
    if (accesses.length > 0){
      options = accesses.map((e) => {      
        return ({value: e.id, label: <div className="d-flex align-items-center">
          <img className="rounded-circle mr-2" width="20" height="20" src={e.imageUrl} alt="" />
          {e.email}
        </div>})
      })
    }

    return (
        <div>
            <Select
            value={selectedOption}
            onChange={(selectedOption) => {
                setSelectedOption(selectedOption)
                setSelectedAccess(selectedOption.value)
            }}
            options={options}
            isMulti={false}
            placeholder={"Sélectionner un compte..."}
            />
            OÙ
        </div>

    )
}

const ConnectionGoogle = ({active, accesses, accessSelected, setSelectedAccess, setInfo}) => {
    return (
        <div className="col">
            <Dropdown accesses={accesses} setSelectedAccess={setSelectedAccess} active={active}/> 
            {active ? (
              <GoogleLogin
                responseType={"code"}
                className="btn btn-primary btn-lg"
                clientId={APP_GOOGLE_ID}
                scope={"https://www.googleapis.com/auth/plus.business.manage"}
                buttonText={"Connection avec Compte"}
                prompt={"consent"}
                onSuccess={e => {
                  setInfo(e);
                }}
                onFailure={e => console.error("ERROR", e)}
                cookiePolicy={"single_host_origin"}
                forceLogin={true}
              /> 
            ) 
            : (
              <a href="#" style={{ pointerEvents: "none" }}>
                Connecter à {accessSelected.email}
              </a>
            )}
          </div> 
    )
    }

export default ConnectionGoogle;
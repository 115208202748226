import React from 'react'
import Header from "./Header";
import Nav from "./Nav";


const Layout = props => (
    <div>
        <Header title={props.title}/>
        <Nav redirectURL={props.redirectURL}/>
        <section className="section">
            <div className="container" style={{ marginTop: 20 }}>
                { props.children }
            </div>
        </section>
    </div>
)

export default Layout;
import styled from '@emotion/styled';
import React from 'react';
import {Link} from 'react-router-dom';

import {SuccessImage} from '../utils/SvgImages'

const InPopIn = styled.div(() => ({
                             position: 'absolute',
                             background: '#FFFFFF',
                             zIndex: 1,
                             borderRadius: '5px',
                           }));

const BackPopIn = styled.div(({display}) => ({
                               position: 'absolute',
                               display: display || 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               width: '100%',
                               height: '100vh',
                               left: '0px',
                               top: '0px',
                               background: 'rgba(0, 0, 0, 0.75)'
                             }));

const SuccessRender = ({media, redirectUrl}) => {
    return (
        <InPopIn className='card-body col-md-4 text-center'>
            <h4 className='card-title'>Synchronisation réussie !</h4>
                { SuccessImage(media) }
            <p className="card-text">
                Nous avons associé vos pages { media } à votre compte Visiretail.
            </p>
            <h6 className='card-subtitle mb-2 text-muted'>Vous devriez être redirigé automatiquement vers votre espace Visiretail.io dans quelques secondes, sinon <Link href={redirectUrl}><a>cliquez ici</a></Link>.</h6>
        </InPopIn>
    );
}

const messageToRedirect = (redirectUrl) => (<div> Vous pouvez recommencer en <a href={redirectUrl}>cliquant ici</a>.</div>)

const ErrorsRender = ({error, redirectUrl}) => (
    <InPopIn className='card-body col-md-4 text-center'>
        <h4 className='card-title'>Une erreur s'est produite !</h4>
        <div className='alert alert-dismissible alert-danger'>
            <strong>Oh oups!</strong>
            <p><strong> {error} </strong></p>
            { redirectUrl ? messageToRedirect(redirectUrl) : null}
            Si le problème persiste, n'hésitez pas à nous contacter à <a href="mailto:tech@visiperf.io">tech@visiperf.io</a>.
        </div>
    </InPopIn>
)

const WaitingRender = () =>
    (<div className = 'spinner-border text-light' role = 'status'>
     <span className = 'sr-only'>chargement en cours...</span>
    </div>)

const PopIn = (props) => {
    let PopInContent = null
    if (props.error && props.error !== '')
        PopInContent = <ErrorsRender error = {props.error} redirectUrl={props.redirectUrl} />
    else if (props.waiting)
        PopInContent = <WaitingRender/>
    else 
        PopInContent = <SuccessRender {...props}/>

    return(
        <BackPopIn>
            {PopInContent}
        </BackPopIn>)
    }

export default PopIn;
import React from 'react'
import {Helmet} from "react-helmet";

const Header = (props) => (
  <Helmet>
    <title>{props.title ? props.title : ""}</title>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootswatch/4.4.1/cosmo/bootstrap.min.css"/>
    <link href="https://fonts.googleapis.com/css?family=Roboto:100,400,500,900&display=swap" rel="stylesheet"/>
    <style jsx global>{`
      body { 
        background: #F5F7FC;
        position: relative;
        font-family: 'Roboto', sans-serif;
      }
    `}</style>
    </Helmet>
  )
  
  export default Header;
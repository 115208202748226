import fetch from 'isomorphic-unfetch';

const AuthUrl = process.env.REACT_APP_AUTH_DOMAIN
const visiconnectUrl = process.env.REACT_APP_VISICONNECT_DOMAIN

export const getUserToken = (refreshToken, callback) => {
  const data = {'token': refreshToken};
  return (
      fetch(
          `${AuthUrl}/v2/tokens`, {method: 'POST', body: JSON.stringify(data)})
          .then(async (response) => {
            const json = await response.json();
            if (callback && json) callback(json);
            return (json);
          })
          .catch(err => console.warn(err)));
};

export const postFacebookPages = (data, businessId, accessToken, callback) => {
  const body = JSON.stringify({
      accessToken: data.access_token,
      pageName: data.name,
      pageId: data.id
  })
  return (fetch(`${visiconnectUrl}/v1/businesses/${businessId}/facebook-accesses-tokens`, {
            method: 'POST',
            headers: {Authorization: `Bearer ${accessToken}`},
            body: body
          })
          .then(async (response) => {
            const json = await response.json();
            if (callback && json) callback(json);
            return (json);
          })
          .catch(err => console.warn(err)));
};

export const postGoogleGTM = (data, businessId, accessToken, callback) => {
  const body = {
    accessToken: data.accessSelected.accessToken,
    accountId: data.accountSelected,
    locationId: data.locationSelected,
    AccessTokenId: data.accessSelected.id
  }
  return (fetch(`${visiconnectUrl}/v1/businesses/${businessId}/google-accesses-tokens`, {
            method: 'POST',
            headers: {Authorization: `Bearer ${accessToken}`},
            body: JSON.stringify(body)
          })
          .then(async (response) => {
            const json = await response.json();
            if (callback && json) callback(json);
            return (json);
          })
          .catch(err => console.warn(err)));
};

export const postGoogleCode = (code, accessToken, callback) => {
  return (fetch(`${visiconnectUrl}/v1/google/code`, {
            method: 'POST',
            headers: {Authorization: `Bearer ${accessToken}`},
            body: JSON.stringify({code: code})
          })
          .then(async (response) => {
            const json = await response.json();
            if (callback && json) callback(json);
            return (json);
          })
          .catch(err => console.warn(err)));
};

export const getGoogleAccesses = (accessToken, callback) => {
  return (fetch(`${visiconnectUrl}/v1/google/accesses`, {
            method: 'GET',
            headers: {Authorization: `Bearer ${accessToken}`},
          })
          .then(async (response) => {
            const json = await response.json();
            if (callback && json) callback(json);
            return (json);
          })
          .catch(err => console.warn(err)));
};
import React from "react";

export const tag1 = (a) => (
  <svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="19.5" cy="19.5" r="19.5" fill={a? "#0057D2": "#D7D7D7"}/>
          <path d="M20.9561 24.3125H18.9805V16.6973L16.6221 17.4287V15.8223L20.7441 14.3457H20.9561V24.3125Z" fill="white"/>
  </svg>
)

export const tag2 = (a) => (
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="19.5" cy="19.5" r="19.5" fill={a? "#0057D2": "#D7D7D7"}/>
  <path d="M22.9795 24.3125H16.1572V22.959L19.377 19.5273C19.819 19.0443 20.1449 18.6227 20.3545 18.2627C20.5687 17.9027 20.6758 17.5609 20.6758 17.2373C20.6758 16.7952 20.5641 16.4489 20.3408 16.1982C20.1175 15.943 19.7985 15.8154 19.3838 15.8154C18.9372 15.8154 18.584 15.9704 18.3242 16.2803C18.069 16.5856 17.9414 16.9889 17.9414 17.4902H15.959C15.959 16.8841 16.1025 16.3304 16.3896 15.8291C16.6813 15.3278 17.0915 14.9359 17.6201 14.6533C18.1488 14.3662 18.748 14.2227 19.418 14.2227C20.4434 14.2227 21.2386 14.4688 21.8037 14.9609C22.3734 15.4531 22.6582 16.1481 22.6582 17.0459C22.6582 17.5381 22.5306 18.0394 22.2754 18.5498C22.0202 19.0602 21.5827 19.6549 20.9629 20.334L18.7002 22.7197H22.9795V24.3125Z" fill="white"/>
</svg>
)

export const tag3 = (a) => (
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="19.5" cy="19.5" r="19.5" fill={a? "#0057D2": "#D7D7D7"}/>
  <path d="M18.167 18.4609H19.2197C19.721 18.4609 20.0924 18.3356 20.334 18.085C20.5755 17.8343 20.6963 17.5016 20.6963 17.0869C20.6963 16.6859 20.5755 16.3737 20.334 16.1504C20.097 15.9271 19.7689 15.8154 19.3496 15.8154C18.9714 15.8154 18.6546 15.9202 18.3994 16.1299C18.1442 16.335 18.0166 16.6038 18.0166 16.9365H16.041C16.041 16.417 16.18 15.9521 16.458 15.542C16.7406 15.1273 17.1325 14.8037 17.6338 14.5713C18.1396 14.3389 18.6956 14.2227 19.3018 14.2227C20.3545 14.2227 21.1794 14.4756 21.7764 14.9814C22.3734 15.4827 22.6719 16.1755 22.6719 17.0596C22.6719 17.5153 22.5329 17.9346 22.2549 18.3174C21.9769 18.7002 21.6123 18.9941 21.1611 19.1992C21.7217 19.3997 22.1387 19.7005 22.4121 20.1016C22.6901 20.5026 22.8291 20.9766 22.8291 21.5234C22.8291 22.4076 22.5055 23.1162 21.8584 23.6494C21.2158 24.1826 20.3636 24.4492 19.3018 24.4492C18.3083 24.4492 17.4948 24.1872 16.8613 23.6631C16.2324 23.139 15.918 22.4463 15.918 21.585H17.8936C17.8936 21.9587 18.0326 22.264 18.3105 22.501C18.5931 22.738 18.9395 22.8564 19.3496 22.8564C19.819 22.8564 20.1859 22.7334 20.4502 22.4873C20.7191 22.2367 20.8535 21.9062 20.8535 21.4961C20.8535 20.5026 20.3066 20.0059 19.2129 20.0059H18.167V18.4609Z" fill="white"/>
</svg>
)


export const tagDone = (a) => (
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="19.5" cy="19.5" r="19.5" fill="#273138"/>
  <path d="M23.2109 15.7175L17.8691 21.1762L14.7769 17.9907C14.6967 17.9085 14.6013 17.843 14.496 17.7981C14.3907 17.7531 14.2777 17.7295 14.1634 17.7286C14.0491 17.7277 13.9357 17.7495 13.8297 17.7928C13.7238 17.8361 13.6273 17.9 13.5458 17.9809C13.4644 18.0618 13.3995 18.1581 13.3549 18.2643C13.3103 18.3705 13.2869 18.4845 13.286 18.5998C13.2842 18.8327 13.3742 19.0568 13.5362 19.2228L17.2584 23.0361C17.3157 23.0861 17.3794 23.1281 17.4478 23.1609C17.4756 23.1831 17.5054 23.2026 17.5367 23.2194C17.6397 23.2622 17.75 23.2847 17.8614 23.2856C17.9729 23.2851 18.0832 23.2626 18.1861 23.2194C18.2232 23.1996 18.2581 23.1761 18.2904 23.1492C18.3534 23.1199 18.4119 23.0818 18.4644 23.0361L24.4362 16.9379C24.5221 16.8582 24.591 16.7618 24.6388 16.6544C24.6867 16.5471 24.7124 16.431 24.7144 16.3133C24.7165 16.1957 24.6949 16.0788 24.6508 15.9698C24.6068 15.8608 24.5413 15.762 24.4582 15.6793C24.3752 15.5966 24.2764 15.5318 24.1678 15.4887C24.0592 15.4457 23.9431 15.4253 23.8265 15.4289C23.7099 15.4325 23.5952 15.4599 23.4894 15.5095C23.3835 15.5591 23.2888 15.6299 23.2109 15.7175Z" fill="white"/>
</svg>
)

export const FacebookIcon = (<g><circle cx="41" cy="41" r="41" fill="#3B5998"/>
<path d="M44.4014 32.3305H48V27H43.7697V27.0192C38.644 27.2013 37.5935 30.0905 37.5009 33.125H37.4903V35.7868H34V41.007H37.4903V55H42.7504V41.007H47.0593L47.8917 35.7868H42.7522V34.1786C42.7522 33.1531 43.4327 32.3305 44.4014 32.3305Z" fill="white"/>
<path d="M115.09 38.8902L121.905 31.2538C122.032 31.1127 122.032 30.8877 121.905 30.7466L115.09 23.1102C115.027 23.0397 114.941 23 114.851 23C114.761 23 114.675 23.04 114.612 23.1102L112.761 25.184C112.635 25.3255 112.635 25.5509 112.762 25.692L115.899 29.1818L102.333 29.1818C102.149 29.1818 102 29.3447 102 29.5454L102 32.4546C102 32.6553 102.149 32.8182 102.333 32.8182L115.899 32.8182L112.762 36.308C112.635 36.4491 112.635 36.6749 112.761 36.8164L114.612 38.8902C114.675 38.9604 114.761 39 114.851 39C114.941 39 115.027 38.9604 115.09 38.8902Z" fill="#3B5998"/>
<path d="M108.91 44.0961L102.095 50.7779C101.968 50.9014 101.968 51.0983 102.095 51.2218L108.91 57.9036C108.973 57.9653 109.059 58 109.149 58C109.239 58 109.325 57.965 109.388 57.9036L111.239 56.089C111.365 55.9652 111.365 55.7679 111.238 55.6445L108.101 52.5909L121.667 52.5909C121.851 52.5909 122 52.4484 122 52.2727L122 49.7273C122 49.5516 121.851 49.4091 121.667 49.4091L108.101 49.4091L111.238 46.3555C111.365 46.2321 111.365 46.0345 111.239 45.9107L109.388 44.0961C109.325 44.0347 109.239 44 109.149 44C109.059 44 108.973 44.0347 108.91 44.0961Z" fill="#8E54E9"/>
</g>)

export const  GoogleIcon = (<g><circle cx="41" cy="41" r="40.5" fill="#F8F8F8" stroke="#E7E7E7"/>
<g clip-path="url(#clip0)">
<path d="M32.0919 44.3379L30.978 48.4961L26.9068 48.5823C25.6901 46.3256 25 43.7436 25 40.9999C25 38.3467 25.6453 35.8447 26.789 33.6416H26.7899L30.4144 34.3061L32.0021 37.9089C31.6698 38.8777 31.4887 39.9177 31.4887 40.9999C31.4888 42.1744 31.7016 43.2997 32.0919 44.3379Z" fill="#FBBB00"/>
<path d="M56.7202 38.011C56.904 38.9789 56.9998 39.9785 56.9998 41C56.9998 42.1455 56.8794 43.2629 56.6499 44.3407C55.8711 48.0084 53.8359 51.211 51.0166 53.4774L51.0157 53.4765L46.4504 53.2436L45.8043 49.2101C47.6751 48.113 49.1371 46.3961 49.9072 44.3407H41.3516V38.011H50.032H56.7202Z" fill="#518EF8"/>
<path d="M51.0162 53.4766L51.0171 53.4774C48.2752 55.6814 44.792 57.0001 41.0004 57.0001C34.9072 57.0001 29.6097 53.5944 26.9072 48.5825L32.0923 44.3381C33.4435 47.9443 36.9222 50.5113 41.0004 50.5113C42.7534 50.5113 44.3956 50.0374 45.8048 49.2102L51.0162 53.4766Z" fill="#28B446"/>
<path d="M51.2128 28.6835L46.0295 32.927C44.5711 32.0154 42.8471 31.4887 41.0001 31.4887C36.8295 31.4887 33.2857 34.1736 32.0022 37.909L26.7899 33.6417H26.7891C29.4519 28.5077 34.8163 25 41.0001 25C44.8822 25 48.4418 26.3829 51.2128 28.6835Z" fill="#F14336"/>
</g></g>
)
export const  TwitterIcon = ""
export const VisiperfIcon = ""

const Icons = {
  "FACEBOOK" : FacebookIcon,
  "GOOGLE" : GoogleIcon,
  "TWITTER" : TwitterIcon
}

export const SuccessImage = (media) => (
  <svg width="224" height="82" viewBox="0 0 224 82" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="183" cy="41" r="41" fill="#8E54E9"/>
    <path d="M199.477 26.6992C198.975 26.1286 198.16 25.9048 197.4 26.1062L197.311 26.1286L182.953 32.0813L168.875 26.1733L168.841 26.1398L168.607 26.0726C167.859 25.8712 167.044 26.095 166.53 26.6657C165.938 27.3258 165.827 28.2881 166.273 29.049L170.103 35.7402L181.111 55.9594C181.468 56.586 182.183 57 182.931 57C183.645 57 184.282 56.6196 184.65 55.9594L195.849 35.7738L199.723 29.0826C200.169 28.3217 200.069 27.3594 199.477 26.6992ZM198.294 28.2546L194.408 34.957L185.075 51.7969L191.572 30.3022L197.858 27.6951C198.003 27.6615 198.16 27.7063 198.249 27.807C198.361 27.9301 198.383 28.1091 198.294 28.2546ZM177.282 34.4535L176.266 31.0743L180.798 32.9765L177.282 34.4535ZM171.554 34.9346L167.702 28.221C167.624 28.0755 167.635 27.8965 167.747 27.7734C167.814 27.6951 167.926 27.6615 168.037 27.6615C168.071 27.6615 168.104 27.6615 168.127 27.6727L174.278 30.2463L175.741 35.1024L172.403 36.5123L171.554 34.9346ZM176.232 36.6913L180.865 52.0431L173.195 37.9669L176.232 36.6913ZM177.773 36.0535L183.165 33.7821L189.596 31.119L182.942 53.162L177.773 36.0535Z" fill="white" stroke="white" stroke-width="0.5" stroke-miterlimit="10"/>
    <circle cx="41" cy="41" r="41" fill="#3B5998"/>
    <path d="M44.4014 32.3305H48V27H43.7697V27.0192C38.644 27.2013 37.5935 30.0905 37.5009 33.125H37.4903V35.7868H34V41.007H37.4903V55H42.7504V41.007H47.0593L47.8917 35.7868H42.7522V34.1786C42.7522 33.1531 43.4327 32.3305 44.4014 32.3305Z" fill="white"/>
    <circle cx="41" cy="41" r="40.5" fill="#F8F8F8" stroke="#E7E7E7"/>
    <path d="M115.09 38.8902L121.905 31.2538C122.032 31.1127 122.032 30.8877 121.905 30.7466L115.09 23.1102C115.027 23.0397 114.941 23 114.851 23C114.761 23 114.675 23.04 114.612 23.1102L112.761 25.184C112.635 25.3255 112.635 25.5509 112.762 25.692L115.899 29.1818L102.333 29.1818C102.149 29.1818 102 29.3447 102 29.5454L102 32.4546C102 32.6553 102.149 32.8182 102.333 32.8182L115.899 32.8182L112.762 36.308C112.635 36.4491 112.635 36.6749 112.761 36.8164L114.612 38.8902C114.675 38.9604 114.761 39 114.851 39C114.941 39 115.027 38.9604 115.09 38.8902Z" fill="#3B5998"/>
    <path d="M108.91 44.0961L102.095 50.7779C101.968 50.9014 101.968 51.0983 102.095 51.2218L108.91 57.9036C108.973 57.9653 109.059 58 109.149 58C109.239 58 109.325 57.965 109.388 57.9036L111.239 56.089C111.365 55.9652 111.365 55.7679 111.238 55.6445L108.101 52.5909L121.667 52.5909C121.851 52.5909 122 52.4484 122 52.2727L122 49.7273C122 49.5516 121.851 49.4091 121.667 49.4091L108.101 49.4091L111.238 46.3555C111.365 46.2321 111.365 46.0345 111.239 45.9107L109.388 44.0961C109.325 44.0347 109.239 44 109.149 44C109.059 44 108.973 44.0347 108.91 44.0961Z" fill="#8E54E9"/>
    
    { Icons[`${media}`] }

  </svg>
) 

import React from 'react'
import Layout                 from '../components/Layout';


export default Error => (
    <Layout>       
      <div>
        <h1>ERROR 404</h1>
        <h5>Cette page n'existe pas !</h5>
      </div>
    </Layout> 
  )
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import FacebookConnect from "./pages/facebookConnect";
import GoogleConnect from "./pages/googleConnect";
import Error from "./pages/error"

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/facebook-connect">
          <FacebookConnect />
        </Route>
        <Route path="/google-connect">
          <GoogleConnect />
        </Route>
        <Route path="*"  >
          <Error />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;

import React from 'react';
import { tagDone, tag3, tag2, tag1 } from "../utils/SvgImages";

const renderTag = function(number, active, done){
    number = (done) ? 0 : number;
    switch (number) {
        case 0:
            return tagDone(active);
        case 1:
            return tag1(active);
        case 2:
            return tag2(active);
        case 3:
            return tag3(active);
        default:
            break
    }
};

const Steps = props => (
    <div className="d-flex justify-content-around" style={StyleCard.addMargin}>
        <div className="flex-sm-fill text-sm-center" style={StyleCard.addMargin}>
            {renderTag(props.number, props.active, props.done)}
        </div>

        <div className={"card  border"+ (props.active ? "-primary": "") + " flex-grow-1 bd-highlight w-100"} style={(props.active) ? StyleCard.activeCard : StyleCard.inactiveCard }>
            <div className="card-body d-flex justify-content-around">
                { props.children }
            </div>
        </div>
    </div>
)

const StyleCard = {
    addMargin: {
        marginTop: 30,
        marginRight: 20
    },
    activeCard: {
        borderRadius: 5,
        border: "1px solid #0057D2",
    },
    inactiveCard: {
        borderRadius: 5,
        border: "2px solid",
        color: "#6F6F6F",
        opacity: "0.5"
    }
}

export default Steps;
import React from 'react';
import {useState} from 'react'
import Select from "react-select";

export const Dropdown = (props) => {
    const [selectedOption, setSelectedOption] = useState([]);

    let options = []
    if (props.data && props.data.length > 0){
        if (props.media === 'FACEBOOK') {
            options = props.data.map((e) => {      
                return ({value: e, label: `${e.name} (${e.category})`})
              })
        } else if (props.media === 'GOOGLE') {
            options = props.data.map((e) => {      
                return ({value: e, label: e.locationName + " ("+e.address?.addressLines.map((elem)=> elem+ " ")+e.address?.locality+""+e.address?.postalCode+")"})
              })
            }
    }
    
    return (
        <Select
        value={props.selected != null? selectedOption : null}
        onChange={(selectedOption) => {
            setSelectedOption(selectedOption)
            props.selectedOption(selectedOption)
        }}
        options={options}
        isMulti={props.isMultiple || false}
      />
    )
};